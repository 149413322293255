import { gql } from '@apollo/client';

export const ADD_WORKFLOW = gql`
  mutation ADD_WORKFLOW(
    $workflow_name: String!
    $workflow_description: String!
  ) {
    insert_workflow(
      objects: {
        workflow_name: $workflow_name
        workflow_description: $workflow_description
      }
    ) {
      returning {
        workflow_id
      }
    }
  }
`;

export const EDIT_WORKFLOW = gql`
  mutation EDIT_WORKFLOW(
    $workflow_id: uuid!
    $workflow_name: String!
    $workflow_description: String!
  ) {
    update_workflow(
      where: { workflow_id: { _eq: $workflow_id } }
      _set: {
        workflow_name: $workflow_name
        workflow_description: $workflow_description
      }
    ) {
      returning {
        workflow_id
      }
    }
  }
`;

export const DELETE_WORKFLOW = gql`
  mutation DELETE_WORKFLOW($workflow_id: uuid!) {
    delete_workflow_task(
      where: { workflow_task_workflow_id: { _eq: $workflow_id } }
    ) {
      returning {
        workflow_task_id
      }
    }
    delete_workflow(where: { workflow_id: { _eq: $workflow_id } }) {
      returning {
        workflow_id
      }
    }
  }
`;

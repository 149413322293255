import { gql } from '@apollo/client';

export const ADD_WORKFLOW_TASK = gql`
  mutation ADD_WORKFLOW_TASK(
    $workflow_task_workflow_id: uuid!
    $workflow_task_name: String!
    $workflow_task_description: String!
    $workflow_task_dynamic_due_from: Int
    $workflow_task_dynamic_lock: Boolean!
    $trigger_workflow_id: uuid
    $task_is_priority: Boolean
  ) {
    insert_workflow_task(
      objects: {
        workflow_task_workflow_id: $workflow_task_workflow_id
        workflow_task_name: $workflow_task_name
        workflow_task_description: $workflow_task_description
        workflow_task_dynamic_due_from: $workflow_task_dynamic_due_from
        workflow_task_dynamic_lock: $workflow_task_dynamic_lock
        trigger_workflow_id: $trigger_workflow_id
        task_is_priority: $task_is_priority
      }
    ) {
      returning {
        workflow_task_id
      }
    }
  }
`;

export const EDIT_WORKFLOW_TASK = gql`
  mutation EDIT_WORKFLOW_TASK(
    $workflow_task_id: uuid!
    $workflow_task_workflow_id: uuid!
    $workflow_task_name: String!
    $workflow_task_description: String!
    $workflow_task_dynamic_due_from: Int
    $workflow_task_dynamic_lock: Boolean!
    $trigger_workflow_id: uuid
    $task_is_priority: Boolean
  ) {
    update_workflow_task(
      where: { workflow_task_id: { _eq: $workflow_task_id } }
      _set: {
        workflow_task_workflow_id: $workflow_task_workflow_id
        workflow_task_name: $workflow_task_name
        workflow_task_description: $workflow_task_description
        workflow_task_dynamic_due_from: $workflow_task_dynamic_due_from
        workflow_task_dynamic_lock: $workflow_task_dynamic_lock
        trigger_workflow_id: $trigger_workflow_id
        task_is_priority: $task_is_priority
      }
    ) {
      returning {
        workflow_task_id
      }
    }
  }
`;

export const DELETE_WORKFLOW_TASK = gql`
  mutation DELETE_WORKFLOW_TASK($workflow_task_id: uuid!) {
    delete_workflow_task(
      where: { workflow_task_id: { _eq: $workflow_task_id } }
    ) {
      returning {
        workflow_task_id
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const ADD_EVENT = gql`
  mutation ADD_EVENT(
    $event_matter_id: uuid!
    $event_name: String!
    $event_location: String!
    $event_county: String!
    $event_owner: Int!
    $event_type: String!
    $event_date_time: timestamptz!
    $event_end_time: timestamptz!
  ) {
    insert_event(
      objects: {
        event_matter_id: $event_matter_id
        event_name: $event_name
        event_location: $event_location
        event_county: $event_county
        event_owner: $event_owner
        event_type: $event_type
        event_date_time: $event_date_time
        event_end_time: $event_end_time
      }
    ) {
      returning {
        event_id
      }
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation EDIT_EVENT(
    $event_id: uuid!
    $event_matter_id: uuid!
    $event_name: String!
    $event_location: String!
    $event_county: String!
    $event_owner: Int!
    $event_type: String!
    $event_date_time: timestamptz!
    $event_end_time: timestamptz!
    $event_complete: Boolean!
  ) {
    update_event(
      where: { event_id: { _eq: $event_id } }
      _set: {
        event_matter_id: $event_matter_id
        event_name: $event_name
        event_location: $event_location
        event_county: $event_county
        event_owner: $event_owner
        event_type: $event_type
        event_date_time: $event_date_time
        event_end_time: $event_end_time
        event_complete: $event_complete
      }
    ) {
      returning {
        event_id
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($event_id: uuid!) {
    delete_task(where: { task_event_id: { _eq: $event_id } }) {
      returning {
        task_id
      }
    }
    delete_event(where: { event_id: { _eq: $event_id } }) {
      returning {
        event_id
      }
    }
  }
`;

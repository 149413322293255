import { gql } from '@apollo/client';

export const switchVisibility = gql`
  mutation SwitchVisibility($article_id: Int, $article_visibility: Boolean) {
    update_article(
      where: { article_id: { _eq: $article_id } }
      _set: { article_visibility: $article_visibility }
    ) {
      affected_rows
    }
  }
`;
export const deleteArticle = gql`
  mutation DeleteArticle($article_id: Int) {
    delete_article(where: { article_id: { _eq: $article_id } }) {
      affected_rows
    }
  }
`;

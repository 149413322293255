import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from '@apollo/client';
import { AUTHENTICATED_ATTORNEY } from '../components/queries';
import { Attorney } from '../utils';

type CurrentAttorneyValues = {
  currentAttorney?: Attorney;
};

const useCurrentAttorney = (): CurrentAttorneyValues => {
  const { user } = useAuth0();
  const [currentAttorney, setCurrentAttorney] = useState<
    Attorney | undefined
  >();

  const [findCurrentAttorney, { data, loading, error }] = useLazyQuery<{
    attorney: Attorney[];
  }>(AUTHENTICATED_ATTORNEY);

  useEffect(() => {
    if (!user.email) return;

    findCurrentAttorney({
      variables: {
        authId: user.email,
      },
    });
  }, [user]);

  useEffect(() => {
    if (data && !loading && !error) {
      setCurrentAttorney(data.attorney[0]);
    }
  }, [data, loading]);

  return { currentAttorney };
};

export { useCurrentAttorney };

import { useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_TASK } from '../components/mutations';
import { EditableMatterTask, Matter, MatterEvent } from '../utils';

type AddTaskParams = {
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type AddTaskValues = {
  onAddTask: (
    taskState: EditableMatterTask,
    matter?: Matter,
    event?: MatterEvent,
  ) => void;
  loading: boolean;
};

const useAddTask = ({ onSuccess, onError }: AddTaskParams): AddTaskValues => {
  const [addTask, { data, loading, error }] = useMutation(ADD_TASK);

  useEffect(() => {
    if (data && !error && !loading) {
      onSuccess();
    }
  }, [data, loading]);

  useEffect(() => {
    if (error) onError(error.message);
  }, [error]);

  const onAddTask = useCallback(
    (
      taskState: EditableMatterTask,
      matter?: Matter,
      event?: MatterEvent,
    ): void => {
      addTask({
        variables: {
          task_matter_id: matter?.matter_id || null,
          task_event_id: event?.event_id || null,
          task_name: taskState.task_name,
          task_description: taskState.task_description,
          task_owner: taskState.task_owner,
          task_due: taskState.task_due,
          task_due_dynamic: taskState.task_due_dynamic,
          task_dynamic_due_from: taskState.task_dynamic_due_from,
          task_completed: taskState.task_completed,
          trigger_workflow_id: taskState.trigger_workflow_id || null,
          task_is_priority: taskState.task_is_priority,
        },
      });
    },
    [addTask],
  );

  return { onAddTask, loading };
};

export { useAddTask };

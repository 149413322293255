import { useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { useColors } from '.';
import { fonts, font } from '../constants/fonts/fonts';

type ThemeMode = 'light' | 'dark';

type MuiThemeValues = {
  theme: { [key: string]: any };
  mode: ThemeMode;
  toggleTheme: () => void;
};

const useMuiTheme = (): MuiThemeValues => {
  const { themeColors } = useColors();
  const [mode, setMode] = useState<ThemeMode>(
    (localStorage.getItem('theme_mode') as ThemeMode | undefined) || 'light',
  );

  const toggleTheme = (): void => {
    if (mode === 'dark') {
      setMode('light');
      localStorage.setItem('theme_mode', 'light');
    } else {
      setMode('dark');
      localStorage.setItem('theme_mode', 'dark');
    }
  };

  const theme = createMuiTheme({
    palette: {
      type: mode,
      primary: { main: themeColors.mainColors.primaryColor },
      secondary: { main: themeColors.mainColors.secondaryColor },
      // @ts-ignore
      alert: { main: themeColors.mainColors.danger },
      // @ts-ignore
      textPrimary: themeColors.textColors.primaryTextColor,
      // @ts-ignore
      textSecondary: themeColors.textColors.secondaryTextColor,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      divider: themeColors.componentColors.dividerColor,
      background: {
        paper: themeColors.mainColors.cardBackground,
      },
    },
    overrides: {
      MuiButton: {
        // @ts-ignore
        label: font.bodyBold,
      },
      MuiTab: {
        // @ts-ignore
        wrapper: font.bodyBold,
      },
      MuiChip: {
        // @ts-ignore
        label: font.bodyBold,
      },
      MuiInputBase: {
        input: font.body,
      },
    },
    typography: fonts,
    status: {
      danger: themeColors.mainColors.danger,
    },
  });

  return { theme, mode, toggleTheme };
};

export { useMuiTheme };

import { gql } from '@apollo/client';

export const fullCaseInfo = gql`
  subscription MySubscription($caseID: uuid) {
    matter(where: { matter_id: { _eq: $caseID } }) {
      matter_appealed
      matter_caption
      matter_docket_link
      matter_client_id
      matter_closed
      matter_court_case_number
      matter_court_id
      matter_id
      matter_opened_date
      matter_name
      matter_details
      matter_parent
      matter_notes
      attorney_owner_id
      matter_type_id
      op_firm_id
      op_counsel_id
      matter_judge_id
      sf_op_id
      stage {
        stage_description
        stage_id
        stage_name
      }
      purpose {
        purpose_description
        purpose_id
        purpose_name
      }
      judge {
        judge_id
        judge_first_name
        judge_middle_name
        judge_last_name
        judge_undesired
        judge_notes
      }
      matter_type {
        matter_type_description
        matter_type_id
        matter_type_name_full
        matter_type_name_short
      }
      owning_attorney {
        attorney_bar_id
        attorney_bar_state
        attorney_email
        attorney_extension
        attorney_id
        attorney_id_color
        attorney_login_id
        attorney_name
      }
      client {
        client_address_city
        client_address_state
        client_address_street
        client_address_zip
        client_address_zip_4
        client_created_date
        client_email
        client_firstName
        client_id
        client_lastName
        client_middleName
        client_phone_cell
        client_phone_home
        client_ssn_last_four
        client_stripe_id
      }
      op_party {
        op_party_address
        op_party_address2
        op_party_address_city
        op_party_address_state
        op_party_address_zip
        op_party_address_zip_4
        op_party_email
        op_party_fax
        op_party_id
        op_party_name
        op_party_phone
      }
      court {
        court_address_city
        court_address_state
        court_address_street
        court_address_zip
        court_address_zip_4
        court_county
        court_efile_link
        court_fax
        court_id
        court_mdj_number
        court_name
        court_phone
        court_state
        court_type
      }
      op_firm {
        op_firm_address1
        op_firm_address2
        op_firm_address_city
        op_firm_address_state
        op_firm_address_zip
        op_firm_address_zip_4
        op_firm_email
        op_firm_fax
        op_firm_id
        op_firm_name
        op_firm_phone
      }
      op_counsel {
        op_counsel_id
        op_counsel_firm_id
        op_counsel_name
        op_firm {
          op_firm_address1
          op_firm_address2
          op_firm_address_city
          op_firm_address_state
          op_firm_address_zip
          op_firm_address_zip_4
          op_firm_email
          op_firm_fax
          op_firm_id
          op_firm_name
          op_firm_phone
        }
      }
      tasks_individual {
        task_matter_id
        task_id
        task_event_id
        task_owner
        task_name
        task_description
        task_dynamic_lock
        task_dynamic_due_from
        task_due_dynamic
        task_due
        task_completed
        task_is_priority
        trigger_workflow_id
        event {
          event_id
          event_name
          event_owner
          event_matter_id
          event_date_time
          event_end_time
        }
        workflowTrigger {
          workflow_description
          workflow_id
          workflow_name
          workflow_tasks {
            workflow_task_description
            workflow_task_dynamic_due_from
            workflow_task_dynamic_lock
            workflow_task_id
            workflow_task_name
            workflow_task_workflow_id
            trigger_workflow_id
            task_is_priority
          }
        }
        attorney {
          attorney_id
          attorney_name
          attorney_id_color
          attorney_login_id
          attorney_extension
          attorney_email
          attorney_bar_state
          attorney_bar_id
          attorney_role
        }
      }
      events {
        event_id
        event_matter_id
        event_name
        event_county
        event_location
        event_owner
        event_type
        event_date_time
        event_end_time
        event_notes
        event_complete
        tasks {
          attorney {
            attorney_id
            attorney_name
            attorney_login_id
            attorney_id_color
            attorney_extension
            attorney_email
            attorney_bar_state
            attorney_bar_id
          }
          event {
            event_id
            event_name
            event_owner
            event_matter_id
            event_date_time
            event_end_time
          }
          task_completed
          task_description
          task_due
          task_due_dynamic
          task_dynamic_due_from
          task_event_id
          task_is_priority
          task_id
          task_matter_id
          task_name
          task_owner
          trigger_workflow_id
          workflowTrigger {
            workflow_description
            workflow_id
            workflow_name
            workflow_tasks {
              workflow_task_description
              workflow_task_dynamic_due_from
              workflow_task_dynamic_lock
              workflow_task_id
              workflow_task_name
              workflow_task_workflow_id
              trigger_workflow_id
            }
          }
        }
        attorney {
          attorney_bar_id
          attorney_bar_state
          attorney_id_color
          attorney_email
          attorney_extension
          attorney_id
          attorney_login_id
          attorney_name
          attorney_role
        }
      }
      expenses {
        expense_added_by
        expense_amount
        expense_category
        expense_client_billable
        expense_id
        expense_matter_id
        expense_name
      }
    }
  }
`;

import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Attorney } from '../components/FormComponents/AttorneySelect';
import { useAddTask } from './useAddTask';
import { EditableMatterTask, Matter, MatterEvent, Workflow } from '../utils';

type AddSelectedParams = {
  onSuccess: () => void;
  onError: (error: string) => void;
};

export type WorkflowOptions = {
  workflow: Workflow;
  attorney: Attorney;
  matter: Matter;
  event?: MatterEvent;
};

type AddSelectedValues = {
  onSelection: (options: WorkflowOptions) => void;
  loading: boolean;
};

type QueuedTask = {
  task: EditableMatterTask;
  matter: Matter;
  event?: MatterEvent;
};

/**
 * This hook utilizes a queue and only fires off a task to add every time
 * the previous task has been added successfully.
 *
 * Basically, we create the whole queue in the onSelection callback. Then
 * we run the onAddTask function every time the queue length changes. We
 * also pop the last item off of the queue on each success to manage the
 * queue length.
 */
const useAddSelectedWorkflow = ({
  onSuccess,
  onError,
}: AddSelectedParams): AddSelectedValues => {
  const [queue, setQueue] = useState<QueuedTask[]>([]);
  const [enqueueComplete, setEnqueueComplete] = useState(false);

  const onLocalError = useCallback(
    (message: string) => {
      onError(message);
      setQueue([]);
    },
    [onError],
  );

  const { onAddTask, loading } = useAddTask({
    onSuccess: () => {
      setQueue(queue.slice(0, -1));
    },
    onError: onLocalError,
  });

  useEffect(() => {
    if (!enqueueComplete) return;

    if (!queue.length) {
      setEnqueueComplete(false);
      return;
    }

    const freshQueue = queue.slice();
    const queuedTask = freshQueue[freshQueue.length - 1];

    if (queuedTask) {
      const { task, matter, event } = queuedTask;
      onAddTask(task, matter, event);
    }
  }, [queue.length, enqueueComplete]);

  useEffect(() => {
    if (enqueueComplete && !queue.length) {
      onSuccess();
    }
  }, [queue.length]);

  const onSelection = useCallback(
    (options: WorkflowOptions): void => {
      const {
        workflow: { workflow_tasks },
        attorney,
        matter,
        event,
      } = options;

      const taskCount = workflow_tasks?.length || 0;

      const localQueue: QueuedTask[] = [];

      for (let i = 0; i < taskCount; i += 1) {
        // eslint-disable-next-line
        const workflowTask = workflow_tasks![i];

        const baseDate = new Date(
          event ? event.event_date_time : moment().toISOString(),
        );

        const isDateFixed = workflowTask.workflow_task_dynamic_lock;

        const taskDue = moment(baseDate)
          .add(workflowTask.workflow_task_dynamic_due_from || 0, 'days')
          .toISOString();

        localQueue.push({
          task: {
            task_name: workflowTask.workflow_task_name || '',
            task_description: workflowTask.workflow_task_description,
            task_owner: attorney.id,
            task_due: taskDue,
            task_due_dynamic: !isDateFixed,
            task_dynamic_due_from: isDateFixed
              ? undefined
              : workflowTask.workflow_task_dynamic_due_from || undefined,
            task_completed: false,
            trigger_workflow_id: workflowTask.trigger_workflow_id || null,
            task_is_priority: workflowTask.task_is_priority,
          },
          matter,
          event,
        });
      }
      setQueue(localQueue);
      setEnqueueComplete(true);
    },
    [setQueue, setEnqueueComplete],
  );

  return { onSelection, loading };
};

export { useAddSelectedWorkflow };

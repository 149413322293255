import { gql } from '@apollo/client';

export const getClientInfo = gql`
  query Client_Info_Subscription($client_id: String) {
    client(where: { client_email: { _eq: $client_id } }) {
      client_id
      client_stripe_id
      client_firstName
      client_middleName
      client_lastName
      client_email
      client_address_street
      client_address_city
      client_address_state
      client_address_zip
      matters {
        matter_appealed
        matter_caption
        matter_closed
        matter_court_case_number
        matter_court_id
        matter_details
        matter_id
        matter_name
        matter_opened_date
        matter_value
        matter_stage
        matter_disposition_date
        matter_type_id
        op_firm_id
        op_counsel_id
        attorney_owner_id
        matter_result
        stage {
          stage_description
          stage_id
          stage_name
        }
        owning_attorney {
          attorney_name
          attorney_email
          attorney_extension
        }
      }
    }
  }
`;

export interface MainColorProps {
  primaryColor: string;
  secondaryColor: string;
  warning: string;
  info: string;
  danger: string;
  success: string;
  cardBackground: string;
  background: string;
  menuColor: string;
}

interface TextColorProps {
  primaryTextColor: string;
  secondaryTextColor: string;
}

interface ComponentColorProps {
  dividerColor: string;
  dividerColorDark: string;
}

interface ChartColorProps {
  fillColor: string;
  lineColor: string;
}

export interface ColorProps {
  mainColors: MainColorProps;
  textColors: TextColorProps;
  componentColors: ComponentColorProps;
  chartColors: ChartColorProps;
}
export interface ThemeColorProps {
  themeColors: ColorProps;
}

const useColors = (): ThemeColorProps => {
  const mode = localStorage.getItem('theme_mode');
  const themeColors = {
    mainColors: {
      primaryColor: mode === 'dark' ? '#5b87ad' : '#2d628f',
      secondaryColor: '#A0ACBD',
      warning: mode === 'dark' ? '#ffb74d' : '#ff9800',
      info: mode === 'dark' ? '#64b5f6' : '#2196f3',
      danger: mode === 'dark' ? '#e57373' : '#f44336',
      success: mode === 'dark' ? '#81c784' : '#4caf50',
      cardBackground:
        mode === 'dark' ? 'rgba(75,75,75,1)' : 'rgba(255,255,255,1)',
      background: mode === 'dark' ? 'rgba(60,60,60,1)' : 'rgba(240,240,240,1)',
      menuColor: mode === 'dark' ? 'rgba(95,95,95,1)' : '#2d628f',
    },
    textColors: {
      primaryTextColor: mode === 'dark' ? 'rgba(175,175,175)' : 'rgb(45,45,45)',
      secondaryTextColor:
        mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(200,200,200)',
    },
    componentColors: {
      dividerColor: mode === 'dark' ? 'rgb(60,60,60)' : 'rgba(100,100,100,.1)',
      dividerColorDark:
        mode === 'dark' ? 'rgb(50,50,50)' : 'rgba(100,100,100,.5)',
    },
    chartColors: {
      fillColor: mode === 'dark' ? 'rgba(255,255,255,.2)' : '#2d628f40',
      lineColor: mode === 'dark' ? '#ffffff90' : 'rgb(245,245,245)',
    },
  };

  return { themeColors };
};

export { useColors };

import { gql } from '@apollo/client';

export const ADD_TASK = gql`
  mutation ADD_TASK(
    $task_matter_id: uuid
    $task_event_id: uuid
    $task_name: String!
    $task_description: String!
    $task_owner: Int!
    $task_due: timestamptz!
    $task_due_dynamic: Boolean!
    $task_dynamic_due_from: Int
    $task_completed: Boolean!
    $trigger_workflow_id: uuid
    $task_is_priority: Boolean
  ) {
    insert_task(
      objects: {
        task_matter_id: $task_matter_id
        task_event_id: $task_event_id
        task_name: $task_name
        task_description: $task_description
        task_owner: $task_owner
        task_due: $task_due
        task_due_dynamic: $task_due_dynamic
        task_dynamic_due_from: $task_dynamic_due_from
        task_completed: $task_completed
        trigger_workflow_id: $trigger_workflow_id
        task_is_priority: $task_is_priority
      }
    ) {
      returning {
        task_id
      }
    }
  }
`;

export const EDIT_TASK = gql`
  mutation EDIT_TASK(
    $task_id: uuid!
    $task_matter_id: uuid
    $task_event_id: uuid
    $task_name: String!
    $task_description: String!
    $task_owner: Int!
    $task_due: timestamptz!
    $task_due_dynamic: Boolean!
    $task_dynamic_due_from: Int
    $task_completed: Boolean!
    $trigger_workflow_id: uuid
    $task_is_priority: Boolean
  ) {
    update_task(
      where: { task_id: { _eq: $task_id } }
      _set: {
        task_id: $task_id
        task_matter_id: $task_matter_id
        task_event_id: $task_event_id
        task_name: $task_name
        task_description: $task_description
        task_owner: $task_owner
        task_due: $task_due
        task_due_dynamic: $task_due_dynamic
        task_dynamic_due_from: $task_dynamic_due_from
        task_completed: $task_completed
        trigger_workflow_id: $trigger_workflow_id
        task_is_priority: $task_is_priority
      }
    ) {
      returning {
        task_id
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DELETE_TASK($task_id: uuid!) {
    delete_task(where: { task_id: { _eq: $task_id } }) {
      returning {
        task_id
      }
    }
  }
`;

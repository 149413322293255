import { useMemo } from 'react';
import moment from 'moment';
import { MatterTask } from './types';

export const useTaskFilter = (
  tasks: MatterTask[],
  filterCompleted?: boolean,
  filterOverdue?: boolean,
  filterInProgress?: boolean,
): Array<MatterTask> => {
  const filteredTasks = useMemo(() => {
    return tasks
      .filter(x => (filterCompleted ? !x?.task_completed : true))
      .filter(x =>
        filterOverdue
          ? moment(x.task_due).isAfter(moment(new Date()).subtract('day', 1))
          : true,
      )
      .filter(x =>
        filterInProgress
          ? moment(x.task_due).isBefore(moment(new Date()).subtract('day', 1))
          : true,
      );
  }, [tasks, filterCompleted, filterOverdue, filterInProgress]);

  return filteredTasks;
};

import { useEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const VoidInvoice = gql`
  mutation MyMutation($id: uuid, $status_type: uuid) {
    update_invoice(
      _set: { invoice_status_type_id: $status_type }
      where: { invoice_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
interface VoidInvoiceValues {
  onVoidInvoice: (id: number) => void;
  loading: boolean;
  data: { updated_invoice: number };
}

export const useVoidInvoice = (): VoidInvoiceValues => {
  const [voidInvoice, { data, loading, error }] = useMutation(VoidInvoice);
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onVoidInvoice = useCallback(
    (id): void => {
      const status_type = '0041b3e7-3602-459f-a586-6a9bfb17d1db';
      voidInvoice({
        variables: {
          id,
          status_type,
        },
      });
    },
    [voidInvoice],
  );

  return { onVoidInvoice, loading, data };
};

import { useMutation, gql } from '@apollo/client';

type NewDataType = string | number | null | boolean | undefined;
type RecordType =
  | 'JudgeFirst'
  | 'JudgeLast'
  | 'JudgeMiddle'
  | 'JudgeNotes'
  | 'JudgeUndesired'
  | 'OpPartyName'
  | 'OpPartyStreet'
  | 'OpPartySuite'
  | 'OpPartyCity'
  | 'OpPartyState'
  | 'OpPartyZip'
  | 'OpPartyZip4'
  | 'OpPartyEmail'
  | 'OpPartyFax'
  | 'OpPartyPhone'
  | 'OpFirmName'
  | 'OpFirmStreet'
  | 'OpFirmSuite'
  | 'OpFirmCity'
  | 'OpFirmState'
  | 'OpFirmZip'
  | 'OpFirmZip4'
  | 'OpFirmEmail'
  | 'OpFirmPhone'
  | 'OpFirmFax'
  | 'CourtName'
  | 'OpCounselName'
  | 'AttyProfileOfficeStreet'
  | 'AttyProfileOfficeSuite'
  | 'AttyProfileOfficeCity'
  | 'AttyProfileOfficeState'
  | 'AttyProfileOfficeZip'
  | 'AttyProfileHomeStreet'
  | 'AttyProfileHomeSuite'
  | 'AttyProfileHomeCity'
  | 'AttyProfileHomeState'
  | 'AttyProfileHomeZip'
  | 'AttyProfileColor';
interface UpdateProfile {
  onSave: (
    recordType: RecordType,
    id: string | number,
    newData: NewDataType,
  ) => void;
  loadingJudgeFirstName: boolean;
  loadingJudgeLastName: boolean;
  loadingJudgeMiddleName: boolean;
  loadingJudgeNotes: boolean;
  loadingJudgeUndesired: boolean;

  loadingOpPartyName: boolean;
  loadingOpPartyStreet: boolean;
  loadingOpPartySuite: boolean;
  loadingOpPartyCity: boolean;
  loadingOpPartyState: boolean;
  loadingOpPartyZip: boolean;
  loadingOpPartyZip4: boolean;
  loadingOpPartyEmail: boolean;
  loadingOpPartyPhone: boolean;
  loadingOpPartyFax: boolean;

  loadingOpFirmName: boolean;
  loadingOpFirmStreet: boolean;
  loadingOpFirmSuite: boolean;
  loadingOpFirmCity: boolean;
  loadingOpFirmState: boolean;
  loadingOpFirmZip: boolean;
  loadingOpFirmZip4: boolean;
  loadingOpFirmEmail: boolean;
  loadingOpFirmPhone: boolean;
  loadingOpFirmFax: boolean;

  loadingCourtName: boolean;
  loadingOpCounselName: boolean;

  loadingAttyProfileOfficeStreet: boolean;
  loadingAttyProfileOfficeSuite: boolean;
  loadingAttyProfileOfficeCity: boolean;
  loadingAttyProfileOfficeState: boolean;
  loadingAttyProfileOfficeZip: boolean;

  loadingAttyProfileHomeStreet: boolean;
  loadingAttyProfileHomeSuite: boolean;
  loadingAttyProfileHomeCity: boolean;
  loadingAttyProfileHomeState: boolean;
  loadingAttyProfileHomeZip: boolean;

  loadingAttyProfileColor: boolean;
}
const AttyProfileColorM = gql`
  mutation UpdateAttorneyColor($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_id_color: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileHomeCityM = gql`
  mutation UpdateAttorneyHomeCity($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_home_city: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileHomeStateM = gql`
  mutation UpdateAttorneyHomeState($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_home_state: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileHomeZipM = gql`
  mutation UpdateAttorneyHomeZip($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_home_zip: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileHomeStreetM = gql`
  mutation UpdateAttorneyHomeStreet($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_home_street: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileHomeSuiteM = gql`
  mutation UpdateAttorneyHomeSuite($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_home_suite: $newData }
    ) {
      affected_rows
    }
  }
`;

const AttyProfileOfficeCityM = gql`
  mutation UpdateAttorneyOfficeCity($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_office_city: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileOfficeStateM = gql`
  mutation UpdateAttorneyOfficeState($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_office_state: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileOfficeZipM = gql`
  mutation UpdateAttorneyOfficeZip($id: Int, $newData: Int) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_office_zip: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileOfficeStreetM = gql`
  mutation UpdateAttorneyOfficeStreet($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_office_street: $newData }
    ) {
      affected_rows
    }
  }
`;
const AttyProfileOfficeSuiteM = gql`
  mutation UpdateAttorneyOfficeSuite($id: Int, $newData: String) {
    update_attorney(
      where: { attorney_id: { _eq: $id } }
      _set: { attorney_office_suite: $newData }
    ) {
      affected_rows
    }
  }
`;
const judgeFirstName = gql`
  mutation judgeMutation($id: uuid, $newData: String) {
    update_judge(
      where: { judge_id: { _eq: $id } }
      _set: { judge_first_name: $newData }
    ) {
      affected_rows
    }
  }
`;
const judgeLastName = gql`
  mutation judgeMutation($id: uuid, $newData: String) {
    update_judge(
      where: { judge_id: { _eq: $id } }
      _set: { judge_last_name: $newData }
    ) {
      affected_rows
    }
  }
`;
const judgeMiddleName = gql`
  mutation judgeMutation($id: uuid, $newData: String) {
    update_judge(
      where: { judge_id: { _eq: $id } }
      _set: { judge_middle_name: $newData }
    ) {
      affected_rows
    }
  }
`;
const judgeNotes = gql`
  mutation judgeMutation($id: uuid, $newData: String) {
    update_judge(
      where: { judge_id: { _eq: $id } }
      _set: { judge_notes: $newData }
    ) {
      affected_rows
    }
  }
`;
const judgeUndesired = gql`
  mutation judgeMutation($id: uuid, $newData: Boolean) {
    update_judge(
      where: { judge_id: { _eq: $id } }
      _set: { judge_undesired: $newData }
    ) {
      affected_rows
    }
  }
`;

const opPartyNameM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_name: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyStreetM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartySuiteM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address2: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyCityM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address_city: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyStateM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address_state: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyZipM = gql`
  mutation opPartyName($id: uuid, $newData: Int) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address_zip: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyZip4M = gql`
  mutation opPartyName($id: uuid, $newData: Int) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_address_zip_4: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyFaxM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_fax: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyEmailM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_email: $newData }
    ) {
      affected_rows
    }
  }
`;
const opPartyPhoneM = gql`
  mutation opPartyName($id: uuid, $newData: String) {
    update_op_party(
      where: { op_party_id: { _eq: $id } }
      _set: { op_party_phone: $newData }
    ) {
      affected_rows
    }
  }
`;

const opFirmNameM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_name: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmStreetM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address1: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmSuiteM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address2: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmCityM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address_city: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmStateM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address_state: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmZipM = gql`
  mutation opFirmName($id: uuid, $newData: Int) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address_zip: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmZip4M = gql`
  mutation opFirmName($id: uuid, $newData: Int) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_address_zip_4: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmFaxM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_fax: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmEmailM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_email: $newData }
    ) {
      affected_rows
    }
  }
`;
const opFirmPhoneM = gql`
  mutation opFirmName($id: uuid, $newData: String) {
    update_op_firm(
      where: { op_firm_id: { _eq: $id } }
      _set: { op_firm_phone: $newData }
    ) {
      affected_rows
    }
  }
`;
const opCounselNameM = gql`
  mutation opCounselName($id: uuid, $newData: String) {
    update_op_counsel(
      where: { op_counsel_id: { _eq: $id } }
      _set: { op_counsel_name: $newData }
    ) {
      affected_rows
    }
  }
`;

const courtNameM = gql`
  mutation courtName($id: uuid, $newData: String) {
    update_court(
      where: { court_id: { _eq: $id } }
      _set: { court_name: $newData }
    ) {
      affected_rows
    }
  }
`;

/**
 * use this hook to update any individual column of any
 * table for profiles. RecordType is required and should
 * be one of the ones listed in the types. id is the id
 * of the filtered record and newData is what you want
 * to overwrite the data with.
 *
 * @example
 * const handleSaveLastName = (): void => {
 *    onSave('JudgeLast', id, newLastName);
 *};
 *
 */
const useUpdateProfile = (): UpdateProfile => {
  const [saveAttorneyColor, { loading: loadingAttyProfileColor }] = useMutation(
    AttyProfileColorM,
  );
  const [
    saveAttorneyHomeCity,
    { loading: loadingAttyProfileHomeCity },
  ] = useMutation(AttyProfileHomeCityM);
  const [
    saveAttorneyHomeState,
    { loading: loadingAttyProfileHomeState },
  ] = useMutation(AttyProfileHomeStateM);
  const [
    saveAttorneyHomeZip,
    { loading: loadingAttyProfileHomeZip },
  ] = useMutation(AttyProfileHomeZipM);
  const [
    saveAttorneyHomeStreet,
    { loading: loadingAttyProfileHomeStreet },
  ] = useMutation(AttyProfileHomeStreetM);
  const [
    saveAttorneyHomeSuite,
    { loading: loadingAttyProfileHomeSuite },
  ] = useMutation(AttyProfileHomeSuiteM);

  const [
    saveAttorneyOfficeCity,
    { loading: loadingAttyProfileOfficeCity },
  ] = useMutation(AttyProfileOfficeCityM);
  const [
    saveAttorneyOfficeState,
    { loading: loadingAttyProfileOfficeState },
  ] = useMutation(AttyProfileOfficeStateM);
  const [
    saveAttorneyOfficeZip,
    { loading: loadingAttyProfileOfficeZip },
  ] = useMutation(AttyProfileOfficeZipM);
  const [
    saveAttorneyOfficeStreet,
    { loading: loadingAttyProfileOfficeStreet },
  ] = useMutation(AttyProfileOfficeStreetM);
  const [
    saveAttorneyOfficeSuite,
    { loading: loadingAttyProfileOfficeSuite },
  ] = useMutation(AttyProfileOfficeSuiteM);
  const [saveJudgeFirstName, { loading: loadingJudgeFirstName }] = useMutation(
    judgeFirstName,
  );
  const [saveJudgeLastName, { loading: loadingJudgeLastName }] = useMutation(
    judgeLastName,
  );
  const [
    saveJudgeMiddleName,
    { loading: loadingJudgeMiddleName },
  ] = useMutation(judgeMiddleName);
  const [saveJudgeNotes, { loading: loadingJudgeNotes }] = useMutation(
    judgeNotes,
  );
  const [saveJudgeUndesired, { loading: loadingJudgeUndesired }] = useMutation(
    judgeUndesired,
  );
  const [saveOpPartyStreet, { loading: loadingOpPartyStreet }] = useMutation(
    opPartyStreetM,
  );
  const [saveOpPartyName, { loading: loadingOpPartyName }] = useMutation(
    opPartyNameM,
  );
  const [saveOpPartySuite, { loading: loadingOpPartySuite }] = useMutation(
    opPartySuiteM,
  );
  const [saveOpPartyCity, { loading: loadingOpPartyCity }] = useMutation(
    opPartyCityM,
  );
  const [saveOpPartyState, { loading: loadingOpPartyState }] = useMutation(
    opPartyStateM,
  );
  const [saveOpPartyZip, { loading: loadingOpPartyZip }] = useMutation(
    opPartyZipM,
  );
  const [saveOpPartyZip4, { loading: loadingOpPartyZip4 }] = useMutation(
    opPartyZip4M,
  );
  const [saveOpPartyEmail, { loading: loadingOpPartyEmail }] = useMutation(
    opPartyEmailM,
  );
  const [saveOpPartyPhone, { loading: loadingOpPartyPhone }] = useMutation(
    opPartyPhoneM,
  );
  const [saveOpPartyFax, { loading: loadingOpPartyFax }] = useMutation(
    opPartyFaxM,
  );
  const [saveOpFirmStreet, { loading: loadingOpFirmStreet }] = useMutation(
    opFirmStreetM,
  );
  const [saveOpFirmName, { loading: loadingOpFirmName }] = useMutation(
    opFirmNameM,
  );
  const [saveOpFirmSuite, { loading: loadingOpFirmSuite }] = useMutation(
    opFirmSuiteM,
  );
  const [saveOpFirmCity, { loading: loadingOpFirmCity }] = useMutation(
    opFirmCityM,
  );
  const [saveOpFirmState, { loading: loadingOpFirmState }] = useMutation(
    opFirmStateM,
  );
  const [saveOpFirmZip, { loading: loadingOpFirmZip }] = useMutation(
    opFirmZipM,
  );
  const [saveOpFirmZip4, { loading: loadingOpFirmZip4 }] = useMutation(
    opFirmZip4M,
  );
  const [saveOpFirmEmail, { loading: loadingOpFirmEmail }] = useMutation(
    opFirmEmailM,
  );
  const [saveOpFirmPhone, { loading: loadingOpFirmPhone }] = useMutation(
    opFirmPhoneM,
  );
  const [saveOpFirmFax, { loading: loadingOpFirmFax }] = useMutation(
    opFirmFaxM,
  );

  const [saveCourtName, { loading: loadingCourtName }] = useMutation(
    courtNameM,
  );

  const [saveOpCounselName, { loading: loadingOpCounselName }] = useMutation(
    opCounselNameM,
  );
  let func;
  const onSave = (
    recordType: RecordType,
    id: string | number,
    newData: NewDataType,
  ): void => {
    switch (recordType) {
      case 'JudgeFirst':
        func = saveJudgeFirstName;
        break;
      case 'JudgeLast':
        func = saveJudgeLastName;
        break;
      case 'JudgeNotes':
        func = saveJudgeNotes;
        break;
      case 'JudgeMiddle':
        func = saveJudgeMiddleName;
        break;
      case 'JudgeUndesired':
        func = saveJudgeUndesired;
        break;
      case 'OpPartyName':
        func = saveOpPartyName;
        break;
      case 'OpPartyStreet':
        func = saveOpPartyStreet;
        break;
      case 'OpPartySuite':
        func = saveOpPartySuite;
        break;
      case 'OpPartyCity':
        func = saveOpPartyCity;
        break;
      case 'OpPartyState':
        func = saveOpPartyState;
        break;
      case 'OpPartyZip':
        func = saveOpPartyZip;
        break;
      case 'OpPartyZip4':
        func = saveOpPartyZip4;
        break;
      case 'OpPartyEmail':
        func = saveOpPartyEmail;
        break;
      case 'OpPartyFax':
        func = saveOpPartyFax;
        break;
      case 'OpPartyPhone':
        func = saveOpPartyPhone;
        break;
      case 'OpFirmName':
        func = saveOpFirmName;
        break;
      case 'OpFirmStreet':
        func = saveOpFirmStreet;
        break;
      case 'OpFirmSuite':
        func = saveOpFirmSuite;
        break;
      case 'OpFirmCity':
        func = saveOpFirmCity;
        break;
      case 'OpFirmState':
        func = saveOpFirmState;
        break;
      case 'OpFirmZip':
        func = saveOpFirmZip;
        break;
      case 'OpFirmZip4':
        func = saveOpFirmZip4;
        break;
      case 'OpFirmEmail':
        func = saveOpFirmEmail;
        break;
      case 'OpFirmFax':
        func = saveOpFirmFax;
        break;
      case 'OpFirmPhone':
        func = saveOpFirmPhone;
        break;
      case 'CourtName':
        func = saveCourtName;
        break;
      case 'OpCounselName':
        func = saveOpCounselName;
        break;
      case 'AttyProfileOfficeStreet':
        func = saveAttorneyOfficeStreet;
        break;
      case 'AttyProfileOfficeSuite':
        func = saveAttorneyOfficeSuite;
        break;
      case 'AttyProfileOfficeCity':
        func = saveAttorneyOfficeCity;
        break;
      case 'AttyProfileOfficeState':
        func = saveAttorneyOfficeState;
        break;
      case 'AttyProfileOfficeZip':
        func = saveAttorneyOfficeZip;
        break;
      case 'AttyProfileHomeStreet':
        func = saveAttorneyHomeStreet;
        break;
      case 'AttyProfileHomeSuite':
        func = saveAttorneyHomeSuite;
        break;
      case 'AttyProfileHomeCity':
        func = saveAttorneyHomeCity;
        break;
      case 'AttyProfileHomeState':
        func = saveAttorneyHomeState;
        break;
      case 'AttyProfileHomeZip':
        func = saveAttorneyHomeZip;
        break;
      case 'AttyProfileColor':
        func = saveAttorneyColor;
        break;
      default:
        break;
    }
    func({
      variables: {
        id,
        newData,
      },
    });
  };
  return {
    onSave,
    loadingJudgeFirstName,
    loadingJudgeLastName,
    loadingJudgeMiddleName,
    loadingJudgeNotes,
    loadingJudgeUndesired,
    loadingOpPartyName,
    loadingOpPartyStreet,
    loadingOpPartySuite,
    loadingOpPartyCity,
    loadingOpPartyState,
    loadingOpPartyZip,
    loadingOpPartyZip4,
    loadingOpPartyEmail,
    loadingOpPartyPhone,
    loadingOpPartyFax,
    loadingOpFirmName,
    loadingOpFirmStreet,
    loadingOpFirmSuite,
    loadingOpFirmCity,
    loadingOpFirmState,
    loadingOpFirmZip,
    loadingOpFirmZip4,
    loadingOpFirmEmail,
    loadingOpFirmPhone,
    loadingOpFirmFax,
    loadingCourtName,
    loadingOpCounselName,
    loadingAttyProfileOfficeStreet,
    loadingAttyProfileOfficeSuite,
    loadingAttyProfileOfficeCity,
    loadingAttyProfileOfficeState,
    loadingAttyProfileOfficeZip,
    loadingAttyProfileHomeStreet,
    loadingAttyProfileHomeSuite,
    loadingAttyProfileHomeCity,
    loadingAttyProfileHomeState,
    loadingAttyProfileHomeZip,
    loadingAttyProfileColor,
  };
};

export { useUpdateProfile };

export const fonts = {
  h1: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h2: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h3: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h4: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h5: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  h6: {
    fontFamily: '"Lato", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  body1: {
    fontFamily: '"Roboto", sans-serif',
    // textShadow: '1px 1px 1px rgba(0,0,0,.3)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  body2: {
    fontFamily: '"Roboto", sans-serif',
    // textShadow: '.5px .5px .6px rgba(0,0,0,.2)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  p: {
    fontFamily: '"Roboto", sans-serif',
    // textShadow: '.5px .5px .6px rgba(0,0,0,.2)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  span: {
    fontFamily: '"Roboto", sans-serif',
    // textShadow: '.5px .5px .6px rgba(0,0,0,.2)',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
};
export const font = {
  body: {
    fontFamily: '"Roboto", sans-serif',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  bodyBold: {
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 'bold',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  title: {
    fontFamily: '"Lato", sans-serif',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
};

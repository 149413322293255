import { gql } from '@apollo/client';

const OP_PARTY = gql`
  mutation ADD_CLIENT(
    $op_party_address: String
    $op_party_address2: String
    $op_party_address_city: String
    $op_party_address_state: String
    $op_party_address_zip: Int
    $op_party_address_zip_4: Int
    $op_party_email: String
    $op_party_fax: String
    $op_party_name: String
    $op_party_phone: String
  ) {
    insert_op_party(
      objects: {
        op_party_address: $op_party_address
        op_party_address2: $op_party_address2
        op_party_address_city: $op_party_address_city
        op_party_address_state: $op_party_address_state
        op_party_address_zip: $op_party_address_zip
        op_party_address_zip_4: $op_party_address_zip_4
        op_party_email: $op_party_email
        op_party_fax: $op_party_fax
        op_party_name: $op_party_name
        op_party_phone: $op_party_phone
      }
    ) {
      returning {
        op_party_id
      }
    }
  }
`;

export const CreditorMutation = gql`
  mutation AddCreditor(
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: Int
    $zip4: Int
    $email: String
    $fax: String
    $name: String
    $phone: String
  ) {
    insert_op_party(
      objects: {
        op_party_address: $address
        op_party_address2: $address2
        op_party_address_city: $city
        op_party_address_state: $state
        op_party_address_zip: $zip
        op_party_address_zip_4: $zip4
        op_party_email: $email
        op_party_fax: $fax
        op_party_name: $name
        op_party_phone: $phone
      }
    ) {
      returning {
        op_party_id
        op_party_name
      }
    }
  }
`;

export const DeleteOpParty = gql`
  mutation DeleteOpParty($op_party_id: uuid) {
    delete_op_party(where: { op_party_id: { _eq: $op_party_id } }) {
      affected_rows
    }
  }
`;

export default OP_PARTY;

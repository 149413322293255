import { gql } from '@apollo/client';

export const GetCaseTypes = gql`
  query GetCaseTypes {
    matter_type {
      matter_type_id
      matter_type_name_full
    }
  }
`;

import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';

const CreateInvoice = gql`
  mutation CreateInvoice(
    $invoice_amount: Int
    $invoice_client_id: uuid
    $invoice_initial_payment: Boolean
    $invoice_due: date
    $invoice_description: String
    $invoice_matter_id: uuid
    $invoice_status_type_id: uuid
  ) {
    insert_invoice(
      objects: {
        invoice_amount: $invoice_amount
        invoice_client_id: $invoice_client_id
        invoice_initial_payment: $invoice_initial_payment
        invoice_due: $invoice_due
        invoice_description: $invoice_description
        invoice_matter_id: $invoice_matter_id
        invoice_status_type_id: $invoice_status_type_id
      }
    ) {
      returning {
        invoice_id
      }
    }
  }
`;

interface CreateInvoiceValues {
  onCreateInvoice: (
    invoice_amount: number,
    invoice_client_id: string,
    invoice_due: string,
    invoice_description: string,
    invoice_initial_payment?: boolean,
    invoice_matter_id?: string | null,
    invoice_status_type_id?: string,
  ) => void;
  loading: boolean;
  data: { invoice_id: string };
}
export const useCreateInvoice = (): CreateInvoiceValues => {
  const [createInvoice, { data, loading, error }] = useMutation(CreateInvoice);
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onCreateInvoice = useCallback(
    (
      invoice_amount,
      invoice_client_id,
      invoice_due,
      invoice_description,
      invoice_initial_payment,
      invoice_matter_id,
      invoice_status_type_id = '90e029c1-6c63-49d6-b458-f435377acd3a',
    ) => {
      createInvoice({
        variables: {
          invoice_amount,
          invoice_client_id,
          invoice_initial_payment,
          invoice_due,
          invoice_description,
          invoice_matter_id,
          invoice_status_type_id,
        },
      });
    },
    [createInvoice],
  );

  return { onCreateInvoice, loading, data };
};

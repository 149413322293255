import { useEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const AmountChange = gql`
  mutation MyMutation($id: uuid, $amt: Int) {
    update_invoice(
      _set: { invoice_amount: $amt }
      where: { invoice_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const useUpdateInvoiceAmount = () => {
  const [updateAmount, { data, loading, error }] = useMutation(AmountChange);
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onUpdateAmount = useCallback(
    (id, amt) => {
      updateAmount({
        variables: {
          id,
          amt,
        },
      });
    },
    [updateAmount],
  );

  return { onUpdateAmount, loading, data, error };
};

import { useSubscription } from '@apollo/client';
import { getClientInvoices } from '../components/queries/invoices';

export const useGetInvoices = (client_id: string): any => {
  const { data, loading } = useSubscription(getClientInvoices, {
    variables: {
      client_id,
    },
  });

  return { data, loading };
};

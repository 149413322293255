import { gql } from '@apollo/client';

export const getClientInfoSubscription = gql`
  subscription Client_Info_Subscription($client_id: uuid) {
    client(where: { client_id: { _eq: $client_id } }) {
      invoices {
        invoice_amount
        invoice_description
        invoice_id
        invoice_matter_id
        invoice_status
        invoice_items {
          invoice_item_amount
          invoice_item_id
          invoice_item_name
        }
        payments {
          payment_amount
          payment_date
          payment_id
          payment_invoice_id
        }
      }
      matters {
        matter_appealed
        matter_caption
        matter_closed
        matter_court_case_number
        matter_court_id
        matter_details
        matter_id
        matter_name
        matter_opened_date
        matter_value
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const AddOpAttorney = gql`
  mutation AddOpAttorney($name: String, $firmId: uuid) {
    insert_op_counsel(
      objects: { op_counsel_name: $name, op_counsel_firm_id: $firmId }
    ) {
      affected_rows
    }
  }
`;

export const DeleteAtty = gql`
  mutation DeleteOpAttorney($op_counsel_id: uuid) {
    delete_op_counsel(where: { op_counsel_id: { _eq: $op_counsel_id } }) {
      affected_rows
    }
  }
`;

import { gql } from '@apollo/client';

export const getClientsSubscription = gql`
  subscription Client_Info_Subscription($searchTerm: String) {
    client(where: { client_lastName: { _ilike: $searchTerm } }) {
      client_address_city
      client_address_state
      client_address_street
      client_address_zip
      client_address_zip_4
      client_created_date
      client_email
      client_firstName
      client_id
      client_lastName
      client_login_id
      client_middleName
      client_phone_cell
      client_phone_home
      client_ssn_last_four
      client_stripe_id
      matters {
        matter_id
        matter_name
        matter_stage
      }
    }
  }
`;

export const getClients = gql`
  query getClientList($searchTerm: String) {
    client(where: { client_lastName: { _ilike: $searchTerm } }) {
      client_address_city
      client_address_state
      client_address_street
      client_address_zip
      client_address_zip_4
      client_created_date
      client_email
      client_firstName
      client_id
      client_lastName
      client_login_id
      client_middleName
      client_phone_cell
      client_phone_home
      client_ssn_last_four
      client_stripe_id
      matters {
        matter_id
        matter_name
        matter_stage
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const DeleteJudge = gql`
  mutation DeleteJudge($judge_id: uuid) {
    delete_judge(where: { judge_id: { _eq: $judge_id } }) {
      affected_rows
    }
  }
`;

export const AddJudge = gql`
  mutation AddJudge(
    $judgeFirstName: String
    $judgeMiddleName: String
    $judgeLastName: String
    $judgeUndesired: Boolean
  ) {
    insert_judge(
      objects: {
        judge_first_name: $judgeFirstName
        judge_last_name: $judgeLastName
        judge_middle_name: $judgeMiddleName
        judge_undesired: $judgeUndesired
      }
    ) {
      affected_rows
    }
  }
`;

import { gql } from '@apollo/client';

export const ADD_COURT = gql`
  mutation ADD_COURT(
    $court_address_city: String
    $court_address_state: String
    $court_address_street: String
    $court_address_street2: String
    $court_address_zip: Int
    $court_address_zip_4: Int
    $court_county: String
    $court_efile_link: String
    $court_fax: String
    $court_mdj_number: String
    $court_name: String
    $court_phone: String
    $court_state: String
    $court_type: String
  ) {
    insert_court(
      objects: {
        court_address_city: $court_address_city
        court_address_state: $court_address_state
        court_address_street: $court_address_street
        court_address_street2: $court_address_street2
        court_address_zip: $court_address_zip
        court_address_zip_4: $court_address_zip_4
        court_county: $court_county
        court_efile_link: $court_efile_link
        court_fax: $court_fax
        court_mdj_number: $court_mdj_number
        court_name: $court_name
        court_phone: $court_phone
        court_state: $court_state
        court_type: $court_type
      }
    ) {
      returning {
        court_id
      }
    }
  }
`;

export const DeleteCourt = gql`
  mutation DeleteCourt($courtId: uuid) {
    delete_court(where: { court_id: { _eq: $courtId } }) {
      affected_rows
    }
  }
`;

import { gql } from '@apollo/client';

export const NEW_CASE = gql`
  mutation ADD_CASE(
    $attorney_owner_id: String
    $matter_client_id: uuid
    $matter_type_id: uuid
    $matter_court_case_number: String
    $matter_court_id: uuid
    $matter_name: String
    $op_firm_id: uuid
    $op_party_id: uuid
    $matter_details: String
  ) {
    insert_matter(
      objects: {
        attorney_owner_id: $attorney_owner_id
        matter_appealed: false
        matter_client_id: $matter_client_id
        matter_type_id: $matter_type_id
        matter_closed: false
        matter_court_case_number: $matter_court_case_number
        matter_court_id: $matter_court_id
        matter_name: $matter_name
        op_firm_id: $op_firm_id
        op_party_id: $op_party_id
        matter_details: $matter_details
      }
    ) {
      returning {
        matter_id
      }
    }
  }
`;

import moment, { Moment } from 'moment';

export const generateDynamicDateMoment = (
  fromDate: string,
  daysFrom: number,
): Moment => {
  return moment(fromDate).add(daysFrom, 'days');
};

export const generateDynamicDateString = (
  fromDate: string,
  daysFrom: number,
): string => {
  return generateDynamicDateMoment(fromDate, daysFrom).toISOString();
};

import { useState, useEffect } from 'react';
import axios from 'axios';

export const useGetClientInvoices = ({ client }) => {
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;
  const [invoices, setInvoices] = useState<any>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);
  const getInvoices = (): any => {
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    };
    const body = { id: client?.client_stripe_id };
    const url = `${baseServerUrl}/get-client-invoices`;
    axios
      .post(url, body, options)
      .then(d => setInvoices(d.data.data))
      .finally(() => setLoadingInvoices(false));
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return { invoices, loadingInvoices };
};

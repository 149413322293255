import { useEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const DeleteInvoice = gql`
  mutation DeleteInvoice($id: uuid) {
    delete_invoice(where: { invoice_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const useDeleteInvoice = () => {
  const [deleteInvoice, { data, loading, error }] = useMutation(DeleteInvoice);
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onDeleteInvoice = useCallback(
    id => {
      const status_type = '3917ba02-f94b-4b0f-8b0e-c8849af4db88';
      deleteInvoice({
        variables: {
          id,
          status_type,
        },
      });
    },
    [deleteInvoice],
  );

  return { onDeleteInvoice, loading, data, error };
};

import { useEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const FinalizeInvoice = gql`
  mutation FinalizeInvoice($id: uuid, $status_type: uuid) {
    update_invoice(
      _set: { invoice_status_type_id: $status_type }
      where: { invoice_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export const useFinalizeInvoice = () => {
  const [finalizeInvoice, { data, loading, error }] = useMutation(
    FinalizeInvoice,
  );
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onFinalizeInvoice = useCallback(
    id => {
      const status_type = '88485b83-2ebf-42f8-a668-c0678aafdac5';
      finalizeInvoice({
        variables: {
          id,
          status_type,
        },
      });
    },
    [finalizeInvoice],
  );

  return { onFinalizeInvoice, loading, data, error };
};

import { gql } from '@apollo/client';

export const OP_FIRM = gql`
  mutation AddOpFirm(
    $op_firm_address: String
    $op_firm_address2: String
    $op_firm_address_city: String
    $op_firm_address_state: String
    $op_firm_address_zip: Int
    $op_firm_address_zip_4: Int
    $op_firm_email: String
    $op_firm_fax: String
    $op_firm_name: String
    $op_firm_phone: String
  ) {
    insert_op_firm(
      objects: {
        op_firm_address1: $op_firm_address
        op_firm_address2: $op_firm_address2
        op_firm_address_city: $op_firm_address_city
        op_firm_address_state: $op_firm_address_state
        op_firm_address_zip: $op_firm_address_zip
        op_firm_address_zip_4: $op_firm_address_zip_4
        op_firm_email: $op_firm_email
        op_firm_fax: $op_firm_fax
        op_firm_name: $op_firm_name
        op_firm_phone: $op_firm_phone
      }
    ) {
      returning {
        op_firm_id
      }
    }
  }
`;

export const DeleteOpFirm = gql`
  mutation DeleteOpFirm($opFirmId: uuid) {
    delete_op_firm(where: { op_firm_id: { _eq: $opFirmId } }) {
      affected_rows
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useWidth } from '../hooks/useWidth';
import { breakpoints } from '../constants';
// comment
export const StarksLawGrid = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  justifyItems,
  justifyContent,
  gridGap,
  alignItems,
  alignContent,
  containerWidth,
  containerHeight,
  containerMargin,
  textAlign,
  isDeveloper,
  padding,
  maxWidth,
}) => {
  const { width } = useWidth();

  let columns;
  if (width < breakpoints.sm) {
    columns = xs;
  } else if (width < breakpoints.md) {
    columns = sm;
  } else if (width < breakpoints.lg) {
    columns = md;
  } else if (width < breakpoints.xl) {
    columns = lg;
  } else {
    columns = xl;
  }

  const container = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    width: containerWidth,
    maxWidth,
    margin: containerMargin,
    justifyItems,
    gridGap,
    alignItems,
    alignContent,
    justifyContent,
    textAlign,
    border: isDeveloper ? '1px solid black' : null,
    height: containerHeight,
    overflow: 'hidden',
    padding,
  };
  return <div style={container}>{children}</div>;
};

StarksLawGrid.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  justifyItems: PropTypes.string,
  justifyContent: PropTypes.string,
  gridGap: PropTypes.string,
  alignItems: PropTypes.string,
  alignContent: PropTypes.string,
  maxWidth: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  containerMargin: PropTypes.string,
  textAlign: PropTypes.string,
  isDeveloper: PropTypes.bool,
  padding: PropTypes.any,
};

StarksLawGrid.defaultProps = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
  justifyItems: null,
  justifyContent: null,
  gridGap: '20px 10px',
  alignItems: 'stretch',
  alignContent: null,
  containerWidth: '95%',
  maxWidth: '400px',
  containerHeight: null,
  containerMargin: '30px auto',
  textAlign: 'center',
  isDeveloper: false,
  padding: 5,
};
export default StarksLawGrid;

import { gql } from '@apollo/client';

export const getClientInvoices = gql`
  subscription GetClientInvoices($client_id: uuid) {
    invoice(where: { client: { client_id: { _eq: $client_id } } }) {
      invoice_amount
      invoice_created_by
      invoice_created_date
      invoice_description
      invoice_due
      invoice_id
      invoice_matter_id
      invoice_client_id
      invoice_initial_payment
      invoice_discounts {
        invoice_discount_amount
        invoice_discount_id
        invoice_discount_invoice_id
        invoice_discount_name
      }
      invoice_internal_notes {
        invoice_internal_note_id
        invoice_internal_note_invoice_id
        invoice_internal_note_text
      }
      invoice_items {
        invoice_item_amount
        invoice_item_id
        invoice_item_name
        invoice_item_qty
      }
      invoice_status_type {
        invoice_status_type_id
        invoice_status_type_name
      }
      invoice_client_notes {
        invoice_client_note_id
        invoice_client_note_invoice_id
        invoice_client_note_text
      }
      payments {
        payment_id
        payment_date
        payment_amount
      }
      matter {
        matter_id
      }
      client {
        client_id
        client_address_city
        client_address_state
        client_address_street
        client_address_zip
        client_email
        client_firstName
        client_lastName
        client_phone_cell
        client_stripe_id
      }
    }
  }
`;

interface CurrentTimeZone {
  zoneAbr?: string;
  zoneFull?: string;
}
const useCurrentTimeZone = (): CurrentTimeZone => {
  const zoneAbr = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];

  const zoneFull = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'long',
    })
    .split(' ')
    .slice(2, 10)
    .join(' ');

  return { zoneAbr, zoneFull };
};

export { useCurrentTimeZone };

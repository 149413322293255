import { useEffect, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const MarkPaid = gql`
  mutation MyMutation($id: uuid, $status_type: uuid) {
    update_invoice(
      _set: { invoice_status_type_id: $status_type }
      where: { invoice_id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export const useMarkInvoicePaid = () => {
  const [markPaid, { data, loading, error }] = useMutation(MarkPaid);
  useEffect(() => {
    if (data && !error && !loading) {
      console.log('success', data);
    }
  }, [data, loading]);
  useEffect(() => {
    if (error) console.log(error.message);
  }, [error]);

  const onMarkInvoicePaid = useCallback(
    id => {
      const status_type = '3917ba02-f94b-4b0f-8b0e-c8849af4db88';
      markPaid({
        variables: {
          id,
          status_type,
        },
      });
    },
    [markPaid],
  );

  return { onMarkInvoicePaid, loading, data, error };
};
